import React from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '../layouts/main';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import FaqSection from '../containers/CryptoModern/FaqSection';

export default function() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('faqs.title')}>
      <FaqSection />
      <Container>
        <Box>
          <div style={{ paddingBottom: '100px' }}>
            <Text
              content={t('faqs.topic2.title')}
              style={{
                marginTop: 0,
                marginBottom: '20px',
                fontSize: '26px',
                color: '#fff',
                display: 'block',
                fontWeight: 300,
                textAlign: 'center'
              }}
            />
            <Text
              content={t('faqs.topic2.desc')}
              style={{
                marginTop: 0,
                marginBottom: '20px',
                fontSize: '26px',
                color: '#fff',
                display: 'block',
                fontWeight: 300,
                textAlign: 'center'
              }}
            />
          </div>
        </Box>
      </Container>
    </MainLayout>
  );
}
