import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { thinDown } from 'react-icons-kit/entypo/thinDown';
import { thinRight } from 'react-icons-kit/entypo/thinRight';
import FaqSectionWrapper from './faqSection.style';

const FaqSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  titleStyle,
  descriptionStyle
}) => {
  const { t } = useTranslation();
  const faqs = [
    {
      title: t('faqs.q1.title'),
      description: t('faqs.q1.description')
    },
    {
      title: t('faqs.q2.title'),
      description: t('faqs.q2.description')
    },
    {
      title: t('faqs.q3.title'),
      description: t('faqs.q3.description')
    },
    {
      title: t('faqs.q4.title'),
      description: t('faqs.q4.description')
    },
    {
      title: t('faqs.q5.title'),
      description: t('faqs.q5.description')
    },
    {
      title: t('faqs.q6.title'),
      description: t('faqs.q6.description')
    },
    {
      title: t('faqs.q7.title'),
      description: t('faqs.q7.description')
    },
    {
      title: t('faqs.q8.title'),
      description: t('faqs.q8.description')
    },
    {
      title: t('faqs.q9.title'),
      description: t('faqs.q9.description')
    },
    {
      title: t('faqs.q10.title'),
      description: t('faqs.q10.description')
    },
    {
      title: t('faqs.q11.title'),
      description: t('faqs.q11.description')
    },
    {
      title: t('faqs.q12.title'),
      description: t('faqs.q12.description')
    },
    {
      title: t('faqs.q13.title'),
      description: t('faqs.q13.description')
    },
    {
      title: t('faqs.q14.title'),
      description: t('faqs.q14.description')
    },
    {
      title: t('faqs.q15.title'),
      description: t('faqs.q15.description')
    },
    {
      title: t('faqs.q16.title'),
      description: t('faqs.q16.description')
    },
    {
      title: t('faqs.q17.title'),
      description: t('faqs.q17.description')
    },
    {
      title: t('faqs.q18.title'),
      description: t('faqs.q18.description')
    },
    {
      title: t('faqs.q19.title'),
      description: t('faqs.q19.description')
    }
  ];
  return (
    <FaqSectionWrapper id="faqSection" style={{ margin: '80px 0' }}>
      <Container>
        <Box {...sectionHeader} className="sectionHeader">
          <Text content={t('faqs.topic')} {...sectionSubTitle} />
          <Heading content={t('faqs.topicDesc')} {...sectionTitle} />
        </Box>
        <Box className="row">
          <Accordion>
            <Fragment>
              {faqs.map((faqItem, index) => (
                <AccordionItem key={`accordion_key-${index}`}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading content={faqItem.title} {...titleStyle} />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={thinRight} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={thinDown} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <Text
                        content={faqItem.description}
                        {...descriptionStyle}
                      />
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
            </Fragment>
          </Accordion>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px']
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['30px', '40px'],
    fontWeight: '300',
    letterSpacing: '-0.025em',
    color: '#fff',
    mb: '15px'
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '18px'],
    fontWeight: '400',
    color: '#496b96',
    mb: '0',
    maxWidth: '420px',
    lineHeight: '1.5'
  },
  // accordion title default style
  titleStyle: {
    fontSize: ['20px', '18px'],
    fontWeight: '400',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '0'
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: '18px',
    color: '#496b96',
    lineHeight: '1.75',
    mb: '0'
  }
};

export default FaqSection;
